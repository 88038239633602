.App {
  text-align: center;
  color: #32de84;
  background-color: #0f3d3e;
  font-family: 'Courier New', Courier, monospace;
}

.App-header {
  background-color: #1a5956;
  padding: 20px;
  border-bottom: 2px solid #32de84;
}

.terminal-window {
  background-color: #122c2d;
  margin: 20px;
  padding: 20px;
  border: 1px solid #32de84;
  box-shadow: 0 0 10px #32de84;
}

.blinking-dot {
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
